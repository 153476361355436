
@mixin animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.slide-enter-active {
  @include animated;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
}

.popIn {
  @include animated;
  -webkit-animation-name: pop-in;
  animation-name: pop-in;
}

.slideInRight {
  @include animated;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes slide {
  0% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes slide {
  0% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(0px);
  }
}
